.sidebar {
   width: 0;
   z-index: 10;
   transition: all 350ms;
   font-size: 0.9rem !important;
}
.sidebar__content {
   overflow-y: auto;
   position: fixed;
   left: -100%;
   height: 100%;
   padding-left: 1.2rem;
   padding-right: 1.2rem;
   flex: 1 1 auto;
   border-right: 1px solid #ddd;
}
.sidebar__content.sidebar-active {
   left: 0;
   width: 80vw;
}

@media screen and (min-width: 992px) {
   .sidebar {
      width: 280px;
      height: 100%;
      flex-shrink: 0;
      border-right: 1px solid rgba(119, 119, 119, 0.2);
      font-size: 0.9rem;
   }
   .sidebar__content {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      left: 0;
      height: 100%;
      flex: 1 0 280px;
      width: 280px;
   }
}

.sidebar .nav__link {
   transition: all ease-in-out 350ms;
}
.sidebar .nav__link:hover {
   background-color: var(--primary-color-light);
}
.sidebar .nav__link .link-icon,
.sidebar .nav__link > i {
   margin-right: 15px;
   font-size: 1.2rem;
}
.sidebar .nav .sub-links {
   margin-left: 1.2rem;
   position: relative;
   display: none;
}
.sidebar .nav .nav__link-subLinks--active > .sub-links {
   display: block;
}
.overlay {
   display: none;
}
.overlay.sidebar-active {
   display: block;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.8);
   position: fixed;
   inset: 0;
   z-index: 5;
}
