@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
   --clr-primary: rgb(2, 149, 216);
   --clr-secondary: #6c757d;
   --clr-white: #fff;
   --bg-clr: #e9e8e5;
   --bg-dark: #464c43;
   --bg-mid: #ccc;
   --danger: #dc3545;
   --danger-bg: #f5c2c7;
   --clr-success: #198754;
   --clr-danger: #dc3545;
   --bg-success: #dfd;
   --bg-danger: #f5c2c7;
   --bg-secondary: #ddd;
}

:root {
   --primary-color: rgb(2, 149, 216);
   --primary-color-light: rgba(2, 149, 217, 0.1);
   --white: #fff;
   --md: 768px;
   --lg: 992px;
   --background-color: #e9e8e5;
   --background-color-dark: #464c43;
   --background-color-mid: #ccc;
   --danger: #dc3545;
   --danger-bg: #f5c2c7;
   --bg-primary: var(--primary-color-light);
}

html {
   box-sizing: border-box;
}

*,
*::before,
*::after {
   box-sizing: inherit;
   padding: 0;
   margin: 0;
}
html,
body,
#root {
   width: 100%;
   height: 100%;
   min-height: 100%;
}
body {
   font-family: "Manrope", sans-serif !important;
   background-color: #efefef !important;
   -webkit-font-smoothing: antialiased;
   line-height: 1;
   font-size: 1rem;
}

.workspace {
   &__album-art,
   &__avatar {
      background-color: var(--background-color);
      background-position: center;
      background-size: cover;
   }
}
progress[value] {
   min-width: 90%;
   appearance: none;
   &::-webkit-progress-bar {
      background-color: white;
      border-radius: 5px;
      border: 1px solid green;
      overflow-x: hidden;
      box-shadow: 0 5px 5px #ddd inset;
   }
   &::-webkit-progress-value {
      border-radius: 5px;
   }
}
.form-error {
   color: red;
   display: block;
}

.custom-select {
   position: relative;
}
.select-control {
   border: 1px solid var(--background-color-mid);
   border-radius: 5px;
   padding: 0.3rem 0.5rem;
   .selected-options {
      min-height: 2.5rem;
      overflow-y: auto;
      flex: 1;
   }
   & .clear-btn {
      border-radius: 5px;
      &:hover {
         color: var(--danger);
         background-color: var(--danger-bg);
      }
   }
   & .clear-btn,
   & .dropdown-btn {
      cursor: pointer;
   }
   .active-option {
      background-color: var(--primary-color);
      color: var(--white);
      &:hover,
      &:focus {
         color: var(--danger);
         background-color: var(--danger-bg);
         cursor: pointer;
         span {
            transform: scale(1.2);
         }
      }
   }
}
.options {
   min-height: 4rem;
   max-height: 15rem;
   user-select: none;
   background-color: var(--white);
   border: 1px solid var(--background-color-mid);
   border-bottom-left-radius: 5px;
   border-bottom-right-radius: 5px;
   outline: none;
   overflow-x: hidden;
   overflow-y: auto;
   display: none;
   position: absolute;
   left: 0;
   right: 0;
   top: calc(100% + 0.25rem);
   z-index: 100;

   &.show-dropdown {
      display: block;
   }
   .option {
      margin-top: 0.2rem;
      padding: 0.5rem 0.8rem;
      &:hover,
      &.highlighted {
         background-color: var(--primary-color);
         color: var(--white);
         cursor: pointer;
      }
   }
}

::-webkit-scrollbar {
   appearance: none;
   width: 0.45rem;
}
::-webkit-scrollbar-track {
   margin-right: 10px;
   width: 70%;
}
::-webkit-scrollbar-thumb {
   border-radius: 0.7rem;
}
:hover::-webkit-scrollbar-thumb {
   background-color: var(--background-color-mid);
}
